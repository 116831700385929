@import "modules/responsive-type.scss";

$primary: #2c5647; /* MAIN COLOR */
$secondary: #42ab85; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fff;
$border-radius: 5px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

// @import url('https://fonts.googleapis.com/css?family=Aref+Ruqaa:400,700');
@import url('https://fonts.googleapis.com/css?family=Fredoka+One');
@import url('https://fonts.googleapis.com/css?family=Exo+2');



// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;

// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;

// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 

$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop


// font-family: 'Gruppo', cursive;
// font-family: 'Exo 2', sans-serif;

html, body {
    height: 100%;
}

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

body {
	// font-size: 2em;
	letter-spacing: 1px;
}

p {
	font-family: 'Exo 2', sans-serif;
    // font-size: 1rem;
}

h1 {
	font-family: 'Fredoka One', cursive;  
  font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h2 {
	font-family: 'Fredoka One', cursive;
  font-size: $mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h3 {
    font-family: 'Fredoka One', cursive; 
  font-size: $mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
}

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill, 
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
}

.flash {
	display:none;
}

.logo {
	@media(max-width: 767px){
		max-height: 70px;
	}
}
nav {
	z-index: 1000;
	background: $primary;
	border:none;
}
.navbar-toggle{
	margin-top: 16px;
}
.navbar-default {
	background: $primary;
	border:none;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;
    background: $primary;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.2);

    .navbar-nav {
        >li>a {
        	font-family: 'Exo 2', sans-serif; 
        	// font-size: 1.2em;
            text-align: center;
            margin-top: 25px;
            display: flex;
            align-items: center;
            color: $wht;
           border-radius: $border-radius;
           transition-duration: 0.5s;

            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }
            &:focus {
            	 background: transparent;
                color: $wht;
            }
            &:active {
                background: #42ab85;
                color: $wht;
                outline: 0;
            }
            &:hover {
                background: #42ab85;
                color: $wht;
                @media (max-width: 1024px) {

                    background: transparent;
                    color: $secondary;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
	margin-top: 0px;
}

.navbar-toggle {
    margin: 18px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;

	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}

	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}

	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}


.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media  (max-width: 767px) {
        display: block;
        /* may need to be inline-block */ 
    }
}

// start sections
section {
	padding: 50px 0;
	position: relative;
}

.section-a {
	background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.75)), url(../img/form_bg.jpg);
	background-repeat: no-repeat;
    background-size: cover;
    // background-attachment: fixed;
    background-position: 50% 50%;
	// background: url('../img/bg1.jpg')  center/cover;
	padding: 0;
	position: relative;
	
	// .container-fluid {
	// 	padding: 0;
	// }

	h1{
		color: $wht;
	}
	
	p{
		margin-top: 15%;
		// font-size: 2em;
		// margin: 0 auto;
		// padding: 5%;
		color: $wht;

		@media(max-width:767px){
			// padding: 20px;
			margin-top: 0;
			// font-size: 1em;
			// text-align: center;
		}
	}
}

.section-b {
	// background: url('../img/bg2.jpg') top left/cover;
	background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.75)), url(../img/intro.jpg);
	background-repeat: no-repeat;
    background-size: cover;
    // background-attachment: fixed;
    background-position: 50% 50%;
	padding: 350px 0 150px;
  	position: relative;

	h1 { 
		color: $wht;
	}

	p {
		color: $wht;
		// font-size: 2em;
		text-transform: uppercase;
	}
	@media (max-width:1024px){
		padding: 200px 0;
	}
	@media (max-width:767px){
		padding: 100px 0;
	}
}
.section-c {
	background: #34735a; /* fallback for old browsers */
	background: -webkit-linear-gradient(to left, #34735a , #023520); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to left, #34735a , #023520); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	// font-size: 1.3em;
	line-height: 2em;

	@media(max-width:767px){
		// font-size: 1em;
		line-height: 1.5em;
	}

	 .img1 {
        background: url(../img/img1.jpg);
        height: 300px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        color: #fff;
        background-color: #f8f8f8;
        border: 5px solid $secondary;
    }

    .img2 {
        background: url(../img/img2.jpg);
        height: 300px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        color: #fff;
        background-color: #f8f8f8;
        border: 5px solid $secondary;
    }

    h1,p{
    	color: $wht;
    }
}

.section-d {

	background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.75)), url(../img/campsite.jpg);
	background-repeat: no-repeat;
    background-size: cover;
    // background-attachment: fixed;
    background-position: 50% 50%;
	// background: #00d2ff; /* fallback for old browsers */
	// background: -webkit-linear-gradient(to left, #00d2ff , #928DAB); /* Chrome 10-25, Safari 5.1-6 */
	// background: linear-gradient(to left, #00d2ff , #928DAB); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	// font-size: 1.3em;
	line-height: 2em;
	padding: 350px 0px;
	color: $wht;

	@media(max-width:767px){
		// font-size: 1em;
		line-height: 1.5em;
	}
}

.form{
	background:rgba(255, 255, 255, 0.5);
	// padding: 50px 100px;
	@media (max-width: 767px){
		// padding: 20px;
	}
}

.cta {
	background: #2c5647;
	padding: 15px 25px;
	line-height: 1em;
	color: $wht;
	// font-size:.8em;
	&:hover {
		background: #42ab85;
		color: $wht;
		transition: all 0.7s ease 0s;
	}
}
// ends sections
/** END LOGIN FORM **/

footer {
	padding: 20px 0px 20px;
	background: #2c5647;
	color: $footerLinks;
	// font-size: .8em;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

@media (max-width: 1024) {
	input#username, input#password {
  		// font-size: 16px;
	}
}


#circle {
    width: 300px;
    height: 300px;
    -moz-border-radius: 150px;
    -webkit-border-radius: 150px;
    border-radius: 150px;
    

    @media (max-width: 991px) {
        width: 225px;
        height: 225px;
        -moz-border-radius: 150px;
        -webkit-border-radius: 150px;
        border-radius: 150px;
    }

    @media (max-width: 768px) {
        width: 200px;
        height: 200px;
        -moz-border-radius: 150px;
        -webkit-border-radius: 150px;
        border-radius: 150px;
    }
}

.shadowthis {
    text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.50);
}

.shadowthat {
    box-shadow: -2px 2px 0px 0px rgba(0, 0, 0, 0.75);
}


